import axios from "axios";
import { url } from "../../data/url";

export default function SidebarButtonsFormat(props) {
  // Allowed extensions for input file
  const allowedExtensions = ["csv"];
  const downloadFile = ({ data, fileName, fileType }) => {
    var BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

    const blob = new Blob([BOM, data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
      encoding: "utf8",
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadCsv = async () => {
    try {
      let res = await axios.get(`${url}/readcsv/`);
      // Headers for each column
      let headers = ["FirstName;FamilyName;PhoneNumber;Diaspora;Language;UserSignupDate;LastKYCAttemptDate;KYCpassedDate;FirstPAUDate;FirstServiceTimeDate;OrderedCard;OrderDate;CardActivationDate"];

      // Convert users data to a csv
      let usersCsv = res.data.rows.reduce((acc, user) => {
        const {
          FirstName,
          FamilyName,
          PhoneNumber,
          Diaspora,
          Language,
          UserSignupDate,
          LastKYCAttemptDate,
          KYCpassedDate,
          FirstPAUDate,
          FirstServiceTimeDate,
          OrderedCard,
          OrderDate,
          CardActivationDate,
        } = user;
        acc.push(
          [
            FirstName,
            FamilyName,
            PhoneNumber,
            Diaspora,
            Language,
            UserSignupDate,
            LastKYCAttemptDate,
            KYCpassedDate,
            FirstPAUDate,
            FirstServiceTimeDate,
            OrderedCard,
            OrderDate,
            CardActivationDate,
          ].join(";")
        );
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: "data.csv",
        fileType: "text/csv;charset=UTF-8",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFileJson = async () => {
    let res = await axios.get(`${url}/readjson/`);

    const blob = new Blob([JSON.stringify(res.data, null, 2)], {
      type: "application/json",
    });
    const url2 = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url2;
    link.download = "lead.json";
    link.click();
    URL.revokeObjectURL(url2);
  };

  return (
    <>
      <div className="p-3 text-center">
        <div
          className="d-flex justify-content-center grid gap-4 btn-group"
          style={{ padding: "0 20%" }}
        >
          <button
            className="mt-3 btn buttons-format flex-fill"
            onClick={() => downloadFileJson()}
          >
            Download JSON format
          </button>

          <button
            className="mt-3 w-30 btn buttons-format flex-fill"
            onClick={() => downloadCsv()}
          >
            Download CSV format
          </button>
        </div>
      </div>
    </>
  );
}
