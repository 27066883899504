import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../../data/url";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import elements from "../../helpers/tipificacion";
import Loader from "../ui/Loader";
const cookie = new Cookies();

export default function FormularioTipificacion({ id, data, setData }) {
  const [user] = useState(cookie.get("user"));
  const [documento_cliente, setDocumento_cliente] = useState("");
  const [celular_cliente, setCelular_cliente] = useState("");
  const [nombres_cliente, setNombres_cliente] = useState("");
  const [apellido_cliente, setApellido_cliente] = useState("");
  const [tipif, setTipif] = useState([]);
  const [m_rechazo, setM_rechazo] = useState("");
  const [m_no_apto, setM_no_apto] = useState("");
  const [seguimiento, setSeguimiento] = useState("");
  const [observation, setObservation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const current = new Date();
  const date = `${current.getFullYear() < 10
    ? "0" + current.getFullYear()
    : current.getFullYear()
    }-${current.getMonth() + 1 < 10
      ? "0" + (current.getMonth() + 1)
      : current.getMonth() + 1
    }-${current.getDate() < 10 ? "0" + current.getDate() : current.getDate()}`;
  const hour = `${current.getHours() < 10 ? "0" + current.getHours() : current.getHours()
    }:${current.getMinutes() < 10
      ? "0" + current.getMinutes()
      : current.getMinutes()
    }:${current.getSeconds() < 10
      ? "0" + current.getSeconds()
      : current.getSeconds()
    }`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      let res = await axios.post(`${url}/createGeneral/typer`, {
        name_custom: `${nombres_cliente} ${apellido_cliente}`,
        id_custom: documento_cliente,
        phone_number: celular_cliente,
        typer: tipif,
        follow:
          tipif === "Venta" ? "Venta" : seguimiento,
        s_rejection: m_rechazo,
        unfit_sale: m_no_apto,
        observationT: observation,
        id_agent: user.UserA,
        name_agent: user.NameU,
        date_r: date,
        hour_R: hour,
      });
      if (res.data !== undefined) {
        setIsLoading(false);
        Swal.fire(res.data.err ? res.data.err : res.data.msg);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        navigate("/typification/consultas")
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form
        className="text-dark p-4 bg-light rounded shadow"
        onSubmit={handleSubmit}
      >
        <div className="row">
          {id && (
            <div className="col-1 mt-3 d-flex justify-content-between">
              <label className="label w-10">ID</label>
              <input
                type="text"
                className="input input-ghost w-full"
                value={id}
                readOnly
              />
            </div>
          )}

          <div className="col-4 mt-3">
            <label className="form-label text-sm">Documento del cliente</label>
            <input
              type="number"
              className="form-control input-bordered w-full"
              value={documento_cliente}
              onChange={(e) => setDocumento_cliente(e.target.value)}
            />
          </div>
          <div className="col-4 mt-3">
            <label className="form-label text-sm"># Celular del cliente</label>
            <input
              type="number"
              className="form-control input-bordered w-full"
              value={celular_cliente}
              onChange={(e) => setCelular_cliente(e.target.value)}
              required
            />
          </div>
          <div className="col-4 mt-3">
            <label className="form-label text-sm">Nombres del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={nombres_cliente}
              onChange={(e) => setNombres_cliente(e.target.value)}
              required
            />
          </div>

          <div className="col-4 mt-3">
            <label className="form-label text-sm">Apellidos del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={apellido_cliente}
              onChange={(e) => setApellido_cliente(e.target.value)}
              required
            ></input>
          </div>

          <div className="col-4 mt-3">
            <label className="form-label text-sm">Tipificación</label>
            <select
              value={tipif ? tipif : null}
              className="form-select"
              onChange={(e) => {
                setTipif(
                  elements.tipificacion.filter((v) => v.resp == e.target.value)
                );
                setTipif(e.target.value);
              }}
              id="floatingSelect"
              aria-label="Floating label select example"
              required
            >
              <option selected value="">
                Seleccione
              </option>
              {[...new Set(elements.tipificacion.map((item) => item))].map(
                (item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                )
              )}
            </select>
          </div>



          {tipif === "Seguimiento" ? (
            <div className="col-4 mt-3">
              <label className="form-label text-sm">Seguimiento</label>
              <select
                className="form-select"
                value={seguimiento ? seguimiento : null}
                onChange={(e) => setSeguimiento(e.target.value)}
                id="floatingSelect"
                aria-label="Floating label select example"
                required
              >
                <option selected value="">
                  Seleccione
                </option>
                {elements.seguimiento.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : tipif === "No venta rechazo" ? (
            <div className="col-4 mt-3">
              <label className="form-label text-sm">No venta rechazo</label>
              <select
                className="form-select"
                value={m_rechazo ? m_rechazo : null}
                onChange={(e) => setM_rechazo(e.target.value)}
                id="floatingSelect"
                aria-label="Floating label select example"
                required
              >
                <option selected value="">
                  Seleccione
                </option>
                {elements.no_venta_rec.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : tipif === "No venta no apto" ? (
            <>
              <div className="col-4 mt-3">
              <label className="form-label text-sm">No venta no apto</label>
              <select
                className="form-select"
                value={m_no_apto ? m_no_apto : null}
                onChange={(e) => setM_no_apto(e.target.value)}
                id="floatingSelect"
                aria-label="Floating label select example"
                required
              >
                <option selected value="">
                  Seleccione
                </option>
                {elements.no_venta_apto.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            
            </>      
          ) : (
            <></>
          )}
           <div className="col-12 mt-3">
            <label className="form-label text-sm">Observación</label>
            <textarea
              rows="2"
              className="form-control resize-none"
              value={observation}
              onChange={(e) => setObservation(e.target.value)}
              required
            ></textarea>  
          </div>
        </div>
        <div className="text-center mt-4">
          {isLoading ? (
            <Loader title={"Ingresando datos,"} />
          ) : (
            ""
          )}
          <button
            disabled={isLoading}
            type="submit"
            className="px-4 btn buttons-primary">
            Registrar Datos
          </button>
        </div>
      </form>
    </>
  );
}
