import { Container, Row, Col } from "react-bootstrap";

import Header from "../general/Header";
import Layout from "../componentes/ui/layout";

export default function Home() {
  return (
    <>
      <div className="d-flex w-100">
        <div className="container-tipif">
          <Header></Header>
        </div>
        <div className="w-100"> {/*width: 89% */}
          <Layout titulo="T&T Tipificación" />

          <div className="d-flex">

            <Container className="container-home mb-3">
              <Row>
                <Col xs={6} md={8}>
                  <div>
                    <img
                      className="mx-auto rounded-3 shadow img-fluid hover:scale-110"
                      src={require("../assets/home/majorityWelcome.png")}
                      alt=""
                    />
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <div>
                    <img
                      className="mx-auto rounded-3 shadow  img-fluid"
                      src={require("../assets/home/GANAR-HISTORIA.png")}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={6} md={8}>
                  <div>
                    <img
                      className="mx-auto rounded-3 shadow  img-fluid"
                      src={require("../assets/home/BANNER-1.992c497b.png")}
                      alt=""
                    />
                  </div>
                </Col>

              </Row> */}
            </Container>
          </div>
        </div>
      </div >
    </>
  );
}
