import axios from "axios";
import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import Swal from "sweetalert2";
import { url } from "../../data/url";
import { FileUploader } from "../../utils/FileUploader";

export default function ModalMasivo(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState("");


  const submitForm = async (e) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", selectedFile);
    let res = await axios.post(`${url}/file-upload`, formData)
    console.log(res)
    if (res.data !== undefined) {
      Swal.fire(res.data.err ? res.data.err : res.data.msg);
      props.setShowModal(false)
      window.location.href = window.location.href;
    }
  }


  return (
    <>
      <Modal show={props.showModal} onHide={props.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Load bulk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Please select the file with the .json format</Form.Label>
              <FileUploader
                onFileSelectSuccess={(file) => setSelectedFile(file)}
                onFileSelectError={({ error }) => alert(error)}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn buttons-secondary w-100" onClick={submitForm}>
          Load JSON
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}