import React from "react";
import { Form } from "react-bootstrap";

export const FileUploader = ({  onFileSelectSuccess }) => {

  const handleFileInput = (e) => {

    // handle validations
    const file = e.target.files[0];
    onFileSelectSuccess(file);
  };

  return (
    <div className="file-uploader">
      <Form.Control
        type="file" onChange={handleFileInput} accept="application/json"
        autoFocus
        required={true}
      />
    </div>
  );
};
