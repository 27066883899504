import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { url } from "../../data/url";
import { FileUploaderCsv } from "../../utils/FileUploaderCsv";

export default function ModalCsv(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState("");

  const submitForm = async (e) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", selectedFile);
    let res = await axios.post(`${url}/file-csv`, formData);
    if (res.data !== undefined) {
      Swal.fire(res.data.err ? res.data.err : res.data.msg);
      props.setShowModalC(false);
      window.location.href = window.location.href;
    }
  };

  return (
    <>
      <Modal show={props.showModalC} onHide={props.handleCloseC}>
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Please select the file with the .csv format</Form.Label>
              <FileUploaderCsv
                onFileSelectSuccess={(file) => setSelectedFile(file)}
                onFileSelectError={({ error }) => alert(error)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn buttons-secondary w-100" onClick={submitForm}>
          Load CSV
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
