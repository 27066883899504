import React from "react";
import { Form } from "react-bootstrap";

export const FileUploaderCsv = ({  onFileSelectSuccess }) => {

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    onFileSelectSuccess(file);
  };

  return (
    <div className="file-uploader">
      <Form.Control
        type="file" onChange={handleFileInput} accept=".csv"  
        autoFocus
      />
    </div>
  );
};
