import ReactEcharts from "echarts-for-react";
import elementos from "../../../helpers/tipificacion";


export const GraphTyper = ({
  dataTyperV,
  dataTyperP,
  dataTyperR,
  dataTypernoA,
}) => {
  const seriesData = elementos.followCategories.map((category) => {
    let data;
    if (category === "Venta") {
      category.startsWith("Venta") 
      data = dataTyperV.filter((item) => item.follow === category).length;

    } else if (
      category.startsWith("Preventa") ||
      category.startsWith("Volver a contactar")
    ) {
      data = dataTyperP.filter((item) => item.follow === category).length;
    } else {
      data = 0;
    }

    return {
      value: data,
      itemStyle: {
        normal: {
          color:
            category === "Venta"
              ? "#47DCA6"
              : category.startsWith("Preventa")
              ? "#58A8FF"
              : category.startsWith("Volver a contactar")
              ? "#FFD464"
              : "",
        },
      },
    };
  });

  const option = {
    title: {
      text: "Total contacto efectivo",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: elementos.followCategories,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.03],
    },
    series: [
      {
        type: "bar",
        data: seriesData,
      },
    ],
  };

  // contacto no efectivo
  let elementNo_contact = elementos.no_venta_rec.concat(
    elementos.no_venta_apto
  );
  const seriesData2 = elementNo_contact.map((category) => {
    let data;
    if (
      category.startsWith("No elegible-") ||
      category.startsWith("No interesado") ||
      category.startsWith("Venta en")
    ) {
      data = dataTypernoA.filter((item) => item.unfit_sale === category).length;
    } else {
      data = dataTyperR.filter((item) => item.s_rejection === category).length;
    }

    return {
      value: data,
      itemStyle: {
        normal: {
          color:
            category.startsWith("No elegible-") ||
            category.startsWith("No interesado") ||
            category.startsWith("Venta en")
              ? "#D2B48C"
              : "#FF735F",
        },
      },
    };
  });

  const option2 = {
    title: {
      text: "Total contacto no efectivo",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: elementos.no_venta_rec.concat(elementos.no_venta_apto),
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.03],
    },
    series: [
      {
        type: "bar",
        data: seriesData2,
      },
    ],
  };

  return (
    <div className="mb-5">
      <div className="bg-light rounded shadow p-4 mb-3">
        <ReactEcharts
          option={option}
        />
      </div>
      <div className="bg-light rounded shadow p-4 mb-3">
        <ReactEcharts option={option2} />
      </div>
    </div>
  );
};
