import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Navbar } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import Cookies from "universal-cookie";
const cookie = new Cookies();


export default function Layout() {
  const [user] = useState(cookie.get("user"));

  const cerrarSesion = () => {
    cookie.remove("user", { path: "/" });
    window.location.href = "./";
  };

  if (!cookie.get("user")) {
    window.location.href = "./";
  }
  return (
    <>
      <div className="container-nav d-flex align-items-center justify-content-between px-2 py-2 shadow position-sticky fixed-top">
        <div>
          <Navbar.Brand href="/home" className="mx-3 fw-bold fs-4">
            <span> <img
              className="rounded-circle me-2"
              style={{ width: '30px' }}
              src={require("../../assets/logo.png")}
              alt="logo-majority"
            />MAJORITY</span>
          </Navbar.Brand>
        </div>
        <div>
          <Navbar
            className=""
            collapseOnSelect
            expand="lg"
            style={{ zIndex: 2, position: "relative" }}
          >
            <Container fluid className="d-flex">
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">

                <div>
                  <NavDropdown
                    title={
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi text-dark bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </div>
                    }
                    id={`offcanvasNavbarDropdown-expand-false`}
                  >
                    <div className="text-center my-2">
                      <h5 className="font-semibold">{user.NameU}</h5>
                    </div>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item href="/" onClick={() => cerrarSesion()}>
                      Log out
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
      <div></div>
    </>
  );
}
