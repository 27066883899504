import { Container, Card, Row } from "react-bootstrap";

import Principal from "../../componentes/leads/consultaPrincipal";
import Header from "../../general/Header";
import Layout from "../../componentes/ui/layout";
import SidebarButton from "../../componentes/leads/SidebarButtons";
import SidebarButtonsFormat from "../../componentes/leads/SidebarButtonsFormat";

export default function Lead() {
  return (
    <>
      <div className="d-flex"  style={{width: "100vw"}}>
        <div className="container-tipif ">
          <Header></Header>
        </div>
        <div style={{width: "83%"}}>
          <Layout titulo="Leads" />
          <div className="mx-5 ">
            <h1 className="title text-center fs-1 fw-normal">
              Load into DB
            </h1>
            <Card className="card border-0 rounded shadow bg-light">
              <SidebarButtonsFormat></SidebarButtonsFormat>
              <SidebarButton></SidebarButton>
            </Card>
            <h1 className="mt-5 title text-center fs-1 fw-normal">
              Lead inquiry and editing
            </h1>
            <Card className="card mt-3 border-0 rounded shadow bg-light">
              <Principal></Principal>
            </Card>
          </div>
        </div>
      </div >
    </>
  );
}
