import { useState } from "react";
import { useParams } from "react-router-dom";
import FormularioPostsale from "../../componentes/Postsale/FormularioPostsale";
import Layout from "../../componentes/ui/layout";
import Header from "../../general/Header";

export default function Ventas() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    return (
        <>
        <div className="d-flex w-100">
        <div className="container-tipif">
          <Header></Header>
        </div>
        

        <div className="w-100"> {/*width: 89% */}
          <Layout titulo="T&T Tipificación" />
          <div className="mx-5 ">
          <h1 className="title text-center fs-1 fw-normal">
                {id ? "Actualizar venta" : "Registrar posventa"}
            </h1>
            <FormularioPostsale id={id} data={data} setData={setData} />
          </div>
          </div>
          </div>
            {/* <div
                className=" d-flex flex-wrap bg-light fixed-top justify-content-between"
                variant="light"
                bg="light"
            >
                <Layout titulo="T&T Tipificación" />
                <Header></Header>
            </div> */}
           

        </>
    )
}