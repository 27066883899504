import { useState } from "react";
import { Card } from "react-bootstrap";
import Cookies from "universal-cookie";
const cookie = new Cookies();

export default function ProfileU() {
  const [user] = useState(cookie.get("user"));

  return (
    <>
      <div>
        <div className="mx-5">
          <Card className="card border-0 rounded shadow bg-light mt-5  py-4">
            <div className="m-auto rounded-circle">
              <img
                className="mx-auto rounded-3 shadow img-fluid hover:scale-110"
                src={require("../../assets/profile/userImg.png")}
                alt=""
              />
            </div>
            <div className="m-auto text-center mt-3">
              <h1 className="title fs-3 fw-bold mb-3">My Profile</h1>
              <h2 className="fw-normal fs-4">{user.NameU}</h2>
              <h2 className="fw-normal fs-5">{user.JobTitle}</h2>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
