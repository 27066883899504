import { useState } from "react";
import axios from "axios";
import React from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { url } from "../../data/url";
import { exportExcelFile } from "../../utils/StructureFileExcel";
const cookie = new Cookies();

export const ApiRequests = ({
  date,
  setDate,
  setTable,
  tabla,
  nameUno, //filtroFch
  initialDate,
  endDate,
  nameCinco,
  idUser,
  document,
  phone,
  num_doc,
}) => {
  const [user] = useState(cookie.get("user"));

  let nameVUser = user.RoleU === 2006 ? 0 : nameCinco;
  let UserDocume = user.RoleU === 2006 ? 0 : idUser;

  const searchAll = async () => {
    try {
      if (num_doc !== "") {
        let res;

        res = await axios.get(`${url}/search/${tabla}/${phone}/${num_doc}`);

        Swal.fire(res.data.err ? res.data.err : res.data.msg);

        if (res.data.rows.length === 0) {
          setDate([]);
          setTable(false);
        } else {
          setDate(res.data.rows);
          setTable(true);
        }

      } else {
        let res = await axios.get(
          `${url}/searchAll/${tabla}/${nameUno}/${initialDate}/${endDate}/${nameVUser}/${UserDocume}`
        );
        Swal.fire(res.data.err ? res.data.err : res.data.msg);
        if (res.data.rows.length === 0) {
          setDate([]);
          setTable(false);
        } else {
          setDate(res.data.rows);
          setTable(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {user.RoleU === 2006 && date.length > 0 && (
        <button
          className="btn btn-success me-4"
          onClick={() => exportExcelFile(tabla, date, initialDate, endDate)}
        >
          <i className="bi bi-file-earmark-excel"></i>
        </button>
      )}
      <button
        type="button"
        className="btn buttons-primary px-5 w-75"
        onClick={searchAll}
      >
        <i className="bi bi-search me-2"></i>Search
      </button>
    </>
  );
};
