import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { url } from "../../data/url";

export default function ModalUpdate(props) {
  const [FirstName, setFirstName] = useState("");
  const [FamilyName, setFamilyName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Diaspora, setDiaspora] = useState("");
  const [Language, setLanguage] = useState("");
  const [UserSignupDate, setUserSignupDate] = useState("");
  const [LastKYCAttemptDate, setLastKYCAttemptDate] = useState("");
  const [KYCpassedDate, setKYCpassedDate] = useState("");
  const [FirstPAUDate, setFirstPAUDate] = useState("");
  const [FirstServiceTimeDate, setFirstServiceTimeDate] = useState("");
  const [OrderedCard, setOrderedCard] = useState("");
  const [OrderDate, setOrderDate] = useState("");
  const [CardActivationDate, setCardActivationDate] = useState("");


  // Mostrar fecha
  const fecha = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };


  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const tabla = "api";
      let campo = "UserId";

      let res = await axios.put(
        `${url}/updateLead/${tabla}/${campo}/${props.data[0].UserId}`,
        {
          FirstName: FirstName,
          FamilyName: FamilyName,
          PhoneNumber: PhoneNumber,
          Diaspora: Diaspora,
          Language: Language,
          UserSignupDate: UserSignupDate,
          LastKYCAttemptDate: LastKYCAttemptDate,
          KYCpassedDate: KYCpassedDate,
          FirstPAUDate: FirstPAUDate,
          FirstServiceTimeDate: FirstServiceTimeDate,
          OrderedCard: OrderedCard,
          OrderDate: OrderDate,
          CardActivationDate: CardActivationDate,
        }
      );
      Swal.fire(res.data.err ? res.data.err : res.data.msg);
      search();
      props.setShowModalU(false);
    } catch (error) {
      console.log(error);
    }
  };
  const search = async () => {
    const tablaSearch = "api";
    let campo = "UserId";
    try {
      let res = await axios.get(
        `${url}/search/${tablaSearch}/${campo}/${props.data[0].UserId}`
      );
      if (res.data.length === 0) {
        props.setData([]);
      } else {
        props.setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewData = (p) => {
    setFirstName(p.FirstName);
    setFamilyName(p.FamilyName);
    setPhoneNumber(p.PhoneNumber);
    setDiaspora(p.Diaspora);
    setLanguage(p.Language);
    setUserSignupDate(p.UserSignupDate);
    setLastKYCAttemptDate(p.LastKYCAttemptDate);
    setKYCpassedDate(p.KYCpassedDate);
    setFirstPAUDate(p.FirstPAUDate);
    setFirstServiceTimeDate(p.FirstServiceTimeDate);
    setOrderedCard(p.OrderedCard);
    setOrderDate(p.OrderDate);
    setCardActivationDate(p.CardActivationDate);
  };
  const clear = () => {
    setFirstName("");
    setFamilyName("");
    setPhoneNumber("");
    setDiaspora("");
    setLanguage("");
    setUserSignupDate("");
    setLastKYCAttemptDate("");
    setKYCpassedDate("");
    setFirstPAUDate("");
    setFirstServiceTimeDate("");
    setOrderedCard("");
    setOrderDate("");
    setCardActivationDate("");
  };
  useEffect(() => {
    props.data.length > 0 ? viewData(props.data[0]) : clear();
  }, [props.data]);

  return (
    <>
      <Modal className="border-0" show={props.showModalU} onHide={props.handleCloseU}>
        <Modal.Header closeButton>
          <Modal.Title>Update Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form onSubmit={submitForm}>
            <Row>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>FirstName</Form.Label>
                  <Form.Control
                    type="text"
                    value={FirstName}
                    required
                    onChange={(e) => setFirstName(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Family Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={FamilyName}
                    required
                    onChange={(e) => setFamilyName(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>PhoneNumber</Form.Label>
                  <Form.Control
                    type="text"
                    value={PhoneNumber}
                    required
                    onChange={(e) => setPhoneNumber(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Diaspora</Form.Label>
                  <Form.Control
                    type="text"
                    value={Diaspora}
                    required
                    onChange={(e) => setDiaspora(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    type="text"
                    value={Language}
                    required
                    onChange={(e) => setLanguage(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>User Signup Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(UserSignupDate)}
                    required
                    onChange={(e) => setUserSignupDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="text-nowrap me-3">Last KYC Attempt Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(LastKYCAttemptDate)}
                    required
                    onChange={(e) =>
                      setLastKYCAttemptDate(() => e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>KYC passed Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(KYCpassedDate)}
                    required
                    onChange={(e) => setKYCpassedDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>First PAU Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(FirstPAUDate)}
                    required
                    onChange={(e) => setFirstPAUDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="text-nowrap">First Service Time Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(FirstServiceTimeDate)}
                    required
                    onChange={(e) =>
                      setFirstServiceTimeDate(() => e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Label>Ordered Card</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={OrderedCard}
                    required
                    onChange={(e) => setOrderedCard(() => e.target.value)}
                  >
                    <option value={OrderedCard}>{OrderedCard}</option>
                    <option
                      value={
                        OrderedCard !== "Yes" && OrderedCard !== "yes"
                          ? "yes"
                          : "no"
                      }
                    >
                      {OrderedCard !== "Yes" && OrderedCard !== "yes"
                        ? "Yes"
                        : "No"}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Order Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(OrderDate)}
                    required
                    onChange={(e) => setOrderDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="text-nowrap">Card Activation Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fecha(CardActivationDate)}
                    required
                    onChange={(e) =>
                      setCardActivationDate(() => e.target.value)
                    }
                  />
                </Form.Group>
              </Col>

              <Button variant="btn buttons-primary w-100" type="submit">
                Update
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
