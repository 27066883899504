import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { url } from "../../data/url";

export default function ModalManual(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [FirstName, setFirstName] = useState("");
  const [FamilyName, setFamilyName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Diaspora, setDiaspora] = useState("");
  const [Language, setLanguage] = useState("");
  const [UserSignupDate, setUserSignupDate] = useState("");
  const [LastKYCAttemptDate, setLastKYCAttemptDate] = useState("");
  const [KYCpassedDate, setKYCpassedDate] = useState("");
  const [FirstPAUDate, setFirstPAUDate] = useState("");
  const [FirstServiceTimeDate, setFirstServiceTimeDate] = useState("");
  const [OrderedCard, setOrderedCard] = useState("");
  const [OrderDate, setOrderDate] = useState("");
  const [CardActivationDate, setCardActivationDate] = useState("");

  const submitForm = async (e) => {
    try {
      e.preventDefault();

      const tabla = "api";
      let res = await axios.post(`${url}/file-manual/${tabla}`, {
        FirstName,
        FamilyName,
        PhoneNumber,
        Diaspora,
        Language,
        UserSignupDate,
        LastKYCAttemptDate,
        KYCpassedDate,
        FirstPAUDate,
        FirstServiceTimeDate,
        OrderedCard,
        OrderDate,
        CardActivationDate,
      });
      if (res.data !== undefined) {
        Swal.fire(res.data.err ? res.data.err : res.data.msg);
        props.setShowModalM(false);
        window.location.href = window.location.href;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clear = () => {
    setFirstName("");
    setFamilyName("");
    setPhoneNumber("");
    setDiaspora("");
    setLanguage("");
    setUserSignupDate("");
    setLastKYCAttemptDate("");
    setKYCpassedDate("");
    setFirstPAUDate("");
    setFirstServiceTimeDate("");
    setOrderedCard("");
    setOrderDate("");
    setCardActivationDate("");
  };
  useEffect(() => {
    clear();
  }, []);
  return (
    <>
    
      <Modal className="w-100" show={props.showModalM} onHide={props.handleCloseM}>
        <Modal.Header closeButton>
          <Modal.Title>Load manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitForm}>
            <Row>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>FirstName</Form.Label>
                  <Form.Control
                    type="text"
                    value={FirstName}
                    required
                    onChange={(e) => setFirstName(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>FamilyName</Form.Label>
                  <Form.Control
                    type="text"
                    value={FamilyName}
                    required
                    onChange={(e) => setFamilyName(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Cedula</Form.Label>
                  <Form.Control
                    type="text"
                    value={PhoneNumber}
                    required
                    onChange={(e) => setPhoneNumber(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Diaspora</Form.Label>
                  <Form.Control
                    type="text"
                    value={Diaspora}
                    required
                    onChange={(e) => setDiaspora(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    type="text"
                    value={Language}
                    required
                    onChange={(e) => setLanguage(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Label>User Signup Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={UserSignupDate}
                    required
                    onChange={(e) => setUserSignupDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Last KYC Attempt Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={LastKYCAttemptDate}
                    required
                    onChange={(e) => setLastKYCAttemptDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>KYC passed Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={KYCpassedDate}
                    required
                    onChange={(e) => setKYCpassedDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>First PAU Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={FirstPAUDate}
                    required
                    onChange={(e) => setFirstPAUDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>First Service Time Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={FirstServiceTimeDate}
                    required
                    onChange={(e) => setFirstServiceTimeDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Ordered Card</Form.Label>
                  <Form.Select aria-label="Default select example" value={OrderedCard} required
                    onChange={(e) => setOrderedCard(() => e.target.value)}>
                    <option value="">Seleccione</option>
                    <option value="yes">yes</option>
                    <option value="no">no</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Order Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={OrderDate}
                    required
                    onChange={(e) => setOrderDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Card Activation Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={CardActivationDate}
                    required
                    onChange={(e) => setCardActivationDate(() => e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Button variant="btn buttons-secondary w-100" type="submit">
                Load lead
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
