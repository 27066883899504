import Layout from "../../componentes/ui/layout";
import Header from "../../general/Header";
import ProfileUser from "../../componentes/profile/ProfileU";

export default function Profile() {
  return (
    <>
      <div className="d-flex w-100">
        <div className="container-tipif">
          <Header></Header>
        </div>
        <div className="w-100">
          <Layout titulo="T&T Tipificación" />
          <div className="w-75 m-auto ">
            <ProfileUser />
          </div>
        </div>
      </div>
    </>
  );
}
