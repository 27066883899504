import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <div className="container" style={{zIndex:2, position:"relative"}}>
        <div className="row flex-column vh-100 justify-content-center align-items-center">
          <div className="text-center">
            <img
              src={require("../assets/ERROR.png")}
              alt="Logo Asiste"
              className="position-relative m-auto"
              style={{ width: "42%" }}
            />
            
          </div>
          <div className="text-center">
            <Link to="/" className="">
                <button className="btn buttons-primary btn-lg   ">
                  Volver al Inicio
                </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
