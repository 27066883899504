import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/pages/Home";
import { Login } from "./pages/Login";
import VentasTypification from "./pages/typification/Ventas";
import VentasSales from "./pages/postsale/Ventas";

import NotFound from "./general/NotFound";
import Search from "./pages/typification/Search";
import SearchPosSales from "./pages/postsale/Search";
import Lead from "./pages/leads/Lead";
import Profile from "./pages/profile/ProfileU";
import GraphT from "./pages/typification/GraphT";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/typification/registrar" element={<VentasTypification />} />
        <Route path="/typification/consultas" element={<Search />} />
        <Route path="/typification/graphs" element={<GraphT />} />
        <Route path="/postsale/registrar" element={<VentasSales />} />
        <Route path="/postsale/consultas" element={<SearchPosSales />} />
        <Route path="/typification/leads" element={<Lead />} />
        <Route path="/profile" element={<Profile />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
