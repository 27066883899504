import { lazy, Suspense, useState } from "react";
import Layout from "../../componentes/ui/layout";
import { FilterGeneral } from "../../componentes/componentsGeneral/FilterGeneral";
import Header from "../../general/Header";
import Loader from "../../componentes/ui/Loader";
const TablePostsale = lazy(() =>
  import("../../componentes/Postsale/TablePostsale")
);


export default function Search() {
  const tabla = "postsale";
  const nameUno = "date_r";
  const nameCinco = "id_agent";
  const tablaSearch = "typer";
  const phone = "phone_number";
  const documento = "id";

  const [table, setTable] = useState(false);
  const [data, setData] = useState([]);

  return (
    <>
      <div className="d-flex" style={{ width: "100vw" }}>
        <div className="container-tipif">
          <Header></Header>
        </div>

        <div style={{ width: "83%" }}>
          <Layout titulo="Typer" />
          <div className="mx-5 ">
            <h1 className="title text-center fs-1 fw-normal">Consultas</h1>
            <FilterGeneral
              date={data}
              setDate={setData}
              setTable={setTable}
              tabla={tabla}
              phone={phone}
              nameUno={nameUno}
              nameCinco={nameCinco}
              tablaSearch={tablaSearch}
              documento={documento}
            />
            <br />
            {table && (
              <Suspense fallback={<Loader />}>
                <TablePostsale data={data} setData={setData} />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
