import { useState } from "react";
import ModalCsv from "../uploadModals/ModalCsv";
import ModalManual from "../uploadModals/ModalManual";
import ModalMasivo from "../uploadModals/ModalMasivo";

export default function SidebarButton() {
  const [showModal, setShowModal] = useState(false);
  const [showModalM, setShowModalM] = useState(false);
  const [showModalC, setShowModalC] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowM = () => setShowModalM(true);
  const handleCloseM = () => setShowModalM(false);
  const handleShowC = () => setShowModalC(true);
  const handleCloseC = () => setShowModalC(false);
  return (
    <>

      <div className="p-3 text-center">
        <div
          className="d-flex justify-content-center grid gap-4 btn-group"
          style={{ padding: "0 20%" }}
        >
          <button
            className="btn buttons-secondary flex-fill"
            onClick={handleShowModal}
          >
            Load JSON
          </button>
          <ModalMasivo
            setShowModal={setShowModal}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
          <button
            className=" w-30 btn buttons-secondary  flex-fill"
            onClick={handleShowC}
          >
            Load CSV
          </button>
          <ModalCsv
            setShowModalC={setShowModalC}
            showModalC={showModalC}
            handleCloseC={handleCloseC}
          />
          <button
            className="w-30 btn buttons-secondary  flex-fill"
            onClick={handleShowM}
          >
            Load manually
          </button>
          <ModalManual
            setShowModalM={setShowModalM}
            showModalM={showModalM}
            handleCloseM={handleCloseM}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button className="w-20 mt-4 mb-2 btn button-aw">
            Sending <b>AWARE</b>
          </button>
        </div>
      </div>
    </>
  );
}
