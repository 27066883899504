const ExcelJS = require("exceljs");

export const exportExcelFile = (tabla, date, initialDate, endDate) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Sales 1");
  sheet.properties.defaultRowHeight = 20;

  // Configurar los bordes de la fila 1
  const borderStyle = { style: "thin", color: { argb: "1E6784" } };
  sheet.getRow(1).border = {
    ...borderStyle,
    top: borderStyle,
    left: borderStyle,
    bottom: borderStyle,
    right: borderStyle,
  };

  sheet.getRow(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "2681A5" },
    bgColor: { argb: "2681A5" },
  };

  sheet.getRow(1).font = {
    name: "Calibri",
    family: 4,
    size: 12,
    bold: true,
    color: { argb: "ffffff" },
  };

  const uniqueKeys = new Set();
  date.forEach((item) => {
    Object.keys(item).forEach((key) => {
      uniqueKeys.add(key);
    });
  });
  const columns = Array.from(uniqueKeys).map((key) => {
    return {
      header: key,
      key: key,
      width: 15, // Ancho predeterminado para todas las columnas
    };
  });

  sheet.columns = columns;

    // Agregar las filas en función de la data proporcionada
    date.forEach((item, index) => {
      const rowNumber = index + 1;
      sheet.addRow(item);
    });


    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `document${tabla}${initialDate}-${endDate}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
};
