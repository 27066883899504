import React from 'react'
import { LoginU } from '../componentes/usuario/Login'

export const Login = () => {
    return (
        <>
            <LoginU  />
        </>
    )
}
