import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../../data/url";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import elements from "../../helpers/tipificacion";
import Loader from "../ui/Loader";
const cookie = new Cookies();

export default function FormularioPostsale({ id, data, setData }) {
  const [user] = useState(cookie.get("user"));
  const [documento_cliente, setDocumento_cliente] = useState("");
  const [celular_cliente, setCelular_cliente] = useState("");
  const [nombres_cliente, setNombres_cliente] = useState("");
  const [apellido_cliente, setApellido_cliente] = useState("");
  const [no_app, setNo_app] = useState([]);
  const [solo_servicio, setSolo_servicio] = useState("");
  const [mas_servicios, setmas_servicios] = useState("");
  const [recomendaria_amigo, setRecomendaria_amigo] = useState("");
  const [observationNo_app, setObservationNo_app] = useState("");
  const [observationSolo_servicio, setObservationSolo_servicio] = useState("");
  const [observationMas_servicios, setObservationMas_servicios] = useState("");
  const [observationRecomiendas, setObservationRecomiendas] = useState("");
  const [contactoRecomienda, setContactoRecomienda] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const current = new Date();
  const date = `${
    current.getFullYear() < 10
      ? "0" + current.getFullYear()
      : current.getFullYear()
  }-${
    current.getMonth() + 1 < 10
      ? "0" + (current.getMonth() + 1)
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? "0" + current.getDate() : current.getDate()}`;
  const hour = `${
    current.getHours() < 10 ? "0" + current.getHours() : current.getHours()
  }:${
    current.getMinutes() < 10
      ? "0" + current.getMinutes()
      : current.getMinutes()
  }:${
    current.getSeconds() < 10
      ? "0" + current.getSeconds()
      : current.getSeconds()
  }`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      let res = await axios.post(`${url}/createGeneral/postsale`, {
        name_custom: `${nombres_cliente} ${apellido_cliente}`,
        id_custom: documento_cliente,
        phone_number: celular_cliente,
        no_app: no_app,
        observationNo_app: observationNo_app,
        solo_servicio: solo_servicio,
        observationSolo_servicio: observationSolo_servicio,
        mas_servicios: mas_servicios,
        observationMas_servicios: observationMas_servicios,
        recomendaria_amigo: recomendaria_amigo,
        observationRecomiendas: observationRecomiendas,
        contactoRecomienda: contactoRecomienda,
        id_agent: user.UserA,
        name_agent: user.NameU,
        date_r: date,
        hour_R: hour,
      });
      if (res.data !== undefined) {
        setIsLoading(false);
        Swal.fire(res.data.err ? res.data.err : res.data.msg);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        navigate("/postsale/consultas");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <form
        className="text-dark p-4 bg-light rounded shadow"
        onSubmit={handleSubmit}
      >
        <div className="row">
          {id && (
            <div className="col-1 mt-3 d-flex justify-content-between">
              <label className="label w-10">ID</label>
              <input
                type="text"
                className="input input-ghost w-full"
                value={id}
                readOnly
              />
            </div>
          )}

          <div className="col-3 mt-3">
            <label className="form-label text-sm">Documento del cliente</label>
            <input
              type="number"
              className="form-control input-bordered w-full"
              value={documento_cliente}
              onChange={(e) => setDocumento_cliente(e.target.value)}
            />
          </div>
          <div className="col-3 mt-3">
            <label className="form-label text-sm"># Celular del cliente</label>
            <input
              type="number"
              className="form-control input-bordered w-full"
              value={celular_cliente}
              onChange={(e) => setCelular_cliente(e.target.value)}
              required
            />
          </div>
          <div className="col-3 mt-3">
            <label className="form-label text-sm">Nombres del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={nombres_cliente}
              onChange={(e) => setNombres_cliente(e.target.value)}
              required
            />
          </div>

          <div className="col-3 mt-3">
            <label className="form-label text-sm">Apellidos del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={apellido_cliente}
              onChange={(e) => setApellido_cliente(e.target.value)}
              required
            ></input>
          </div>

          <div className="col-6 mt-3">
            <label className="form-label text-sm">No utiliza el app</label>
            <select
              value={no_app ? no_app : null}
              className="form-select"
              onChange={(e) => setNo_app(e.target.value)}
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              <option selected value="">
                Seleccione
              </option>
              {elements.no_usa_app.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {no_app.length != 0 && (
            <div className="col-6 mt-3">
              <label className="form-label text-sm">
                Observación
              </label>
              <textarea
                rows="2"
                className="form-control resize-none"
                value={observationNo_app}
                onChange={(e) => setObservationNo_app(e.target.value)}
              ></textarea>
            </div>
          )}
          <div className="col-6 mt-3">
            <label className="form-label text-sm">Solo usa un servicio</label>
            <select
              className="form-select"
              value={solo_servicio ? solo_servicio : null}
              onChange={(e) => setSolo_servicio(e.target.value)}
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              <option selected value="">
                Seleccione
              </option>
              {elements.solo_servicio.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {solo_servicio.length != 0 && (
            <div className="col-6 mt-3">
              <label className="form-label text-sm">
                Observación
              </label>
              <textarea
                rows="2"
                className="form-control resize-none"
                value={observationSolo_servicio}
                onChange={(e) => setObservationSolo_servicio(e.target.value)}
              ></textarea>
            </div>
          )}

          <div className="col-6 mt-3">
            <label className="form-label text-sm">
              Ha utilizado más de un servicio
            </label>
            <select
              className="form-select"
              value={mas_servicios ? mas_servicios : null}
              onChange={(e) => setmas_servicios(e.target.value)}
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              <option selected value="">
                Seleccione
              </option>
              {elements.mas_servicio.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {mas_servicios.length != 0 && (
            <div className="col-6 mt-3">
              <label className="form-label text-sm">
                Observación
              </label>
              <textarea
                rows="2"
                className="form-control resize-none"
                value={observationMas_servicios}
                onChange={(e) => setObservationMas_servicios(e.target.value)}
              ></textarea>
            </div>
          )}
          <div className="col-6 mt-3">
            <label className="form-label text-sm">
              Recomendarías el servicio a un amigo
            </label>
            <select
              className="form-select"
              value={recomendaria_amigo ? recomendaria_amigo : null}
              onChange={(e) => setRecomendaria_amigo(e.target.value)}
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              <option selected value="">
                Seleccione
              </option>
              {elements.servicio_amigo.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          {recomendaria_amigo.length != 0 && (
            <>
              <div
                className={
                  recomendaria_amigo == "si" ? "col-3 mt-3" : "col-6 mt-3"
                }
              >
                <label className="form-label text-sm">
                  Observación
                </label>
                <textarea
                  rows="2"
                  className="form-control resize-none"
                  value={observationRecomiendas}
                  onChange={(e) => setObservationRecomiendas(e.target.value)}
                ></textarea>
              </div>
              {recomendaria_amigo === "si" && (
                <div className="col-3 mt-3 ">
                  <label className="form-label text-sm">Contacto</label>
                  <input
                    type="text"
                    className="form-control input-bordered w-full"
                    value={contactoRecomienda}
                    onChange={(e) => setContactoRecomienda(e.target.value)}
                    required
                  />
                </div>
              )}
            </>
          )}

          <div className="text-center mt-4">
            {isLoading ? <Loader title={"Ingresando datos,"} /> : ""}
            <button
              disabled={isLoading}
              type="submit"
              className="px-4 btn buttons-primary"
            >
              Registrar Datos
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
