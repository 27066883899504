import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../data/url";
import moment from "moment/moment";
import ModalUpdate from "../uploadModals/ModalUpdate";
import ReactPaginate from "react-paginate";
import { Table } from "react-bootstrap";

export default function Principal() {
  const [pageNumber, setPageNumber] = useState(0);
  const [showModalU, setShowModalU] = useState(false);
  const [data, setData] = useState([]);

  const dataPerPage = 24;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(data.length / dataPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleShowU = () => setShowModalU(true);
  const handleCloseU = () => setShowModalU(false);

  // Mostrar fecha
  const fecha = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const searchAll = async () => {
    let tabla = "api";
    try {
      let res = await axios.get(`${url}/consultLead/${tabla}`);
      if (res.data.length === 0) {
        setData([]);
      } else {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterRow = (row) => {
    setData(
      data.filter((element) => {
        return element.UserId === row.UserId;
      })
    );
  };
  useEffect(() => {
    searchAll();
  }, []);

  return (
    <>
      <div className="w-100">
        <div
          className="overflow rounded shadow p-4 bg-light">
          <div className="mh-25">
            <ReactPaginate
              previousLabel={
                <button
                  data-id="previous-page-button"
                  aria-disabled="false"
                  aria-label="Goto Previous Page"
                  className="border-0 bg-light "
                >
                  <svg
                    fill="currentColor"
                    width="6.46px"
                    height="10px"
                    viewBox="0 0 6.46 10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0.239130435,4.47204969 L4.46273292,0.248447205 C4.75465839,-0.0434782609 5.22670807,-0.0434782609 5.51552795,0.248447205 L6.2173913,0.950310559 C6.50931677,1.24223602 6.50931677,1.71428571 6.2173913,2.00310559 L3.22670807,5 L6.22049689,7.99378882 C6.51242236,8.28571429 6.51242236,8.75776398 6.22049689,9.04658385 L5.51863354,9.7515528 C5.22670807,10.0434783 4.75465839,10.0434783 4.46583851,9.7515528 L0.242236025,5.52795031 C-0.0527950311,5.23602484 -0.0527950311,4.76397516 0.239130435,4.47204969 Z"></path>
                  </svg>
                </button>
              }
              nextLabel={
                <button
                  data-id="next-page-button"
                  aria-disabled="false"
                  aria-label="Goto Next Page"
                  className="border-0 bg-light"
                >
                  <svg
                    fill="currentColor"
                    width="6.46px"
                    height="10px"
                    viewBox="0 0 6.46 10"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.22049689,5.52795031 L1.99689441,9.7515528 C1.70496894,10.0434783 1.23291925,10.0434783 0.944099379,9.7515528 L0.242236025,9.04968944 C-0.049689441,8.75776398 -0.049689441,8.28571429 0.242236025,7.99689441 L3.23602484,5.00310559 L0.242236025,2.00931677 C-0.049689441,1.7173913 -0.049689441,1.24534161 0.242236025,0.956521739 L0.940993789,0.248447205 C1.23291925,-0.0434782609 1.70496894,-0.0434782609 1.99378882,0.248447205 L6.2173913,4.47204969 C6.51242236,4.76397516 6.51242236,5.23602484 6.22049689,5.52795031 Z"></path>
                  </svg>
                </button>
              }
              pageCount={pageCount}
              pageClassName={
                "btn btn-sm mx-0.5 rounded border-0"
              }
              onPageChange={changePage}
              containerClassName={"mh-25 p-1 d-flex justify-content-end paginationBttns list-unstyled "}
              previousLinkClassName={
                "btn btn-sm border border-1 me-2 list-unstyled shadow-md border-transparent  rounded previousBttn"
              }
              nextLinkClassName={
                "btn btn-sm border border-1 ms-2 list-unstyled shadow-md border-transparent  rounded"
              }
              disabledClassName={" disabled paginationDisabled"}
              activeClassName={
                "btn btn-sm buttons-primary paginationActive"
              }
            />
          </div>
          <Table responsive className=" table  table-bordered table-striped table-sm table-fixed">
            <thead className="text-center align-middle">
              <tr>
                <th className="text-center"></th>
                <th className="text-nowrap" >Id</th>
                <th className="text-nowrap">First Name</th>
                <th className="text-nowrap">Family Name</th>
                <th className="text-nowrap">Phone Number</th>
                <th className="text-nowrap">Diaspora</th>
                <th className="text-nowrap">Language</th>
                <th className="text-nowrap">User signup Date</th>
                <th className="text-nowrap">Last KYC Attempt Date</th>
                <th className="text-nowrap">KYC passed Date</th>
                <th className="text-nowrap">First PAU Date</th>
                <th className="text-nowrap">First Service Time Date</th>
                <th className="text-nowrap">Ordered Card</th>
                <th className="text-nowrap">Order Date</th>
                <th className="text-nowrap">Card Activation Date</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((item) => (
                  <tr key={item.UserId} className="text-center px-4 table-row">
                    <td className="text-center">
                      <button
                        className="btn border-0"
                        onClick={() => {
                          handleShowU();
                          filterRow(item);
                        }}
                      >
                        <i className="bi bi-pencil-square text-primary"></i>
                      </button>
                    </td>
                    <ModalUpdate
                      data={data}
                      setData={setData}
                      idItem={item.UserId}
                      setShowModalU={setShowModalU}
                      showModalU={showModalU}
                      handleCloseU={handleCloseU}
                    />
                    <td className="text-nowrap px-1">{item.UserId}</td>
                    <td className="text-nowrap px-1">{item.FirstName}</td>
                    <td className="text-nowrap px-1">{item.FamilyName}</td>
                    <td className="text-nowrap px-1">{item.PhoneNumber}</td>
                    <td className="text-nowrap px-1">{item.Diaspora}</td>
                    <td className="text-nowrap px-1">{item.Language}</td>
                    <td className="text-nowrap px-1">{fecha(item.UserSignupDate)}</td>
                    <td className="text-nowrap px-1">{fecha(item.LastKYCAttemptDate)}</td>
                    <td className="text-nowrap px-1">{fecha(item.KYCpassedDate)}</td>
                    <td className="text-nowrap px-1">{fecha(item.FirstPAUDate)}</td>
                    <td className="text-nowrap px-1">{fecha(item.FirstServiceTimeDate)}</td>
                    <td className="text-nowrap px-1">{item.OrderedCard}</td>
                    <td className="text-nowrap px-1">{fecha(item.OrderDate)}</td>
                    <td className="text-nowrap px-1">{fecha(item.CardActivationDate)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
