import React from "react";
import { ApiRequests } from "../services/ApiRequests";
import { useState } from "react";
import Cookies from "universal-cookie";
import { Col, Form, Row } from "react-bootstrap";

export const FilterGeneral = ({
  date,
  setDate,
  setTable,
  tabla,
  nameUno, //filtroFch
  nameCinco,
  tablaSearch,
  documento,
  document,
  phone,
}) => {
  const cookie = new Cookies();

  const current = new Date();
  const dateNow = `${current.getFullYear().toString().padStart(2, "0")}-${(
    current.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;

  const [user] = useState(cookie.get("user"));
  const [initialDate, setInitialDate] = useState(dateNow);
  const [endDate, setendDate] = useState(dateNow);
  const [num_doc, setNumDoc] = useState("");
  return (
    <>
      <div className="rounded shadow p-4 bg-light">
        <Row className="justify-content-end align-items-end">
          <Col sm={3}>
            <Form.Label className="form-label text-sm">
              Fecha Inicial
            </Form.Label>
            <Form.Control
              type="date"
              className="input-bordered"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <Form.Label className="form-label text-sm">Fecha Final</Form.Label>
            <Form.Control
              type="date"
              className="input-bordered"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <Form.Label className="form-label text-sm">
              Buscar ID
            </Form.Label>
            <Form.Control
              type="text"
              className="input-bordered"
              placeholder="Teléfono o documento"
              value={num_doc}
              onChange={(e) => setNumDoc(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <ApiRequests
              date={date}
              setDate={setDate}
              setTable={setTable}
              tabla={tabla}
              nameUno={nameUno}
              document={document}
              phone={phone}
              initialDate={initialDate}
              endDate={endDate}
              num_doc={num_doc}
              nameCinco={nameCinco}
              idUser={user.UserA}
              tablaSearch={tablaSearch}
              documento={documento}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
